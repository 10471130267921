import React from 'react';

import './index.scss';

const Footer = () => (
    <footer className="footer">
        <div className="footer__content">
            MountainsLovers.pl
        </div>
    </footer>
);

export default Footer;