import React, { Component } from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import OutsideClickHandler from "react-outside-click-handler"

import "./index.scss"

class Nav extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showNav: false,
    }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.setState((prevState) => ({
      showNav: !prevState.showNav,
    }))
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query MenuQuery {
            allStrapiCategory(sort: { fields: strapiId }) {
              edges {
                node {
                  name
                  slug
                  strapiId
                }
              }
            }
          }
        `}
        render={(data) => (
          <OutsideClickHandler
            onOutsideClick={() => {
              this.setState({ showNav: false })
            }}
          >
            <nav className="nav">
              <button
                className={`nav__toggle hamburger hamburger--slider ${
                  this.state.showNav ? "is-active" : ""
                }`}
                onClick={this.handleClick}
                type="button"
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
              <ul
                className={`nav__items ${
                  this.state.showNav ? "is-active" : ""
                }`}
              >
                {data.allStrapiCategory.edges.map(({ node }) => (
                  <li key={node.slug} className="nav__item">
                    <Link
                      className="nav__link"
                      to={`/${node.slug}/`}
                      activeClassName="nav__link--active"
                    >
                      {node.name}
                    </Link>
                  </li>
                ))}
                  <li key="galeria" className="nav__item">
                    <Link
                      className="nav__link"
                      to={`/galeria/`}
                      activeClassName="nav__link--active"
                    >
                      Galeria
                    </Link>
                  </li>
              </ul>
            </nav>
          </OutsideClickHandler>
        )}
      />
    )
  }
}

export default Nav