import React from 'react';
import PropTypes from "prop-types";
import Instagram from '@assets/instagram-inline.svg'
import Facebook from '@assets/facebook-inline.svg'

import './index.scss';

const Social = ({type}) => (
    <div className="social">
        <a
            href="https://www.instagram.com/mountainslovers.pl"
            rel="noopener noreferrer"
            target="_blank"
         >
            <Instagram className={`social__icon${type === 'light' ? ' social__icon--light' : ''}`} />
        </a>
        <a
            href="http://facebook.com/mountainsloverspl"
            rel="noopener noreferrer"
            target="_blank"
         >
            <Facebook className={`social__icon${type === 'light' ? ' social__icon--light' : ''}`} />
        </a>
    </div>
);


Social.propTypes = {
    type: PropTypes.string,
}

export default Social;