import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from '@assets/logo-inline.svg'
import Nav from '@components/Nav'
import Social from '@components/Social'

import './index.scss'

const Header = ({ siteTitle }) => (
  <header className="header">
    <div className="header__container">
      <Link className="header__brand" to="/">
          <div className="header__logo">
            <Logo />
          </div>
          <h1 className="header__title">
            {siteTitle}
            <span className="header__title--small">blog o turystyce górskiej</span>
          </h1>
      </Link>
      <Social />
      <Nav />
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
