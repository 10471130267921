import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import CookieConsent from "react-cookie-consent";
import SimpleReactLightbox from 'simple-react-lightbox'

import Header from "./Header"
import Footer from './Footer'
import './layout.scss'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <SimpleReactLightbox>
        <Header siteTitle={data.site.siteMetadata?.title || `MountainsLovers.pl`} />
        <main className="main">{children}</main>
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="OK"
          style={{ fontSize: "12px" }}
        >
          Nasza strona internetowa używa plików cookies (tzw. ciasteczka) w celach statystycznych, reklamowych oraz funkcjonalnych. Dzięki nim możemy indywidualnie dostosować stronę do twoich potrzeb. Każdy może zaakceptować pliki cookies albo ma możliwość wyłączenia ich w przeglądarce, dzięki czemu nie będą zbierane żadne informacje.
        </CookieConsent>
      </SimpleReactLightbox>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
